import { Flex, Box, Text, Heading } from "@chakra-ui/react";
import { AnimatedText, SkillTags } from "../components";

export const ExperienceSection = () => {
  return (
    <Flex
      width="100%"
      height="100%"
      color="#fff"
      fontSize="18px"
      lineHeight={"33px"}
      flexDir="column"
    >
      <Flex flexDir="column" w="100%">
        <Flex flexDir="column" mr="20px" fontSize={"16px"}>
          <AnimatedText>
            12/2022 -
            <Text as="span" fontSize={"18px"}>
              Present
            </Text>
          </AnimatedText>
          <AnimatedText>
            <Heading size="md">
              Mid-level FullStack Engineer - SendFX Limited
            </Heading>
          </AnimatedText>
        </Flex>
        <Flex
          flexDir={"column"}
          fontSize={"14px"}
          color="#838485"
          lineHeight="25px"
        >
          <AnimatedText>
            <Text mt="10px">
              Build and maintain micro-services architecture that powers 10000+
              users and many enterprises to handle payments.
            </Text>
            <Text mt="15px">
              Designed and developed public API that allow enterprises to
              integrate into Send's system and harness the power of easy payment
            </Text>
            <Text mt="15px">
              Enhanced maintainability by contributing to infrastructure design
              and architecture and established coding standards and best
              practices, ensuring consistency across the codebase.
            </Text>
            <Text mt="15px">
              Conducted thorough code reviews to maintain high coding standards,
              providing constructive feedback to team members for continuous
              improvement.
            </Text>
            <SkillTags
              skills={[
                "Micro-Services",
                "PostgreSQL",
                "React",
                "TypeScript",
                "AWS",
                "Serverless",
                "NodeJS",
                "CircleCI",
                "Jest",
              ]}
            />
          </AnimatedText>
        </Flex>

        <Flex flexDir="column" mt="50px" mr="20px" fontSize={"16px"}>
          <Box>03/2021 - 12/2022</Box>
          <Heading size="md">Junior Engineer - SendFX Limited</Heading>
        </Flex>
        <Flex
          flexDir={"column"}
          fontSize={"14px"}
          color="#838485"
          lineHeight="25px"
          mb="20px"
        >
          <AnimatedText>
            <Text mt="10px">
              Working on developing features both frontend and backend, and some
              dev-ops for websites including Portal, internal Admin websites,
              landing pages
            </Text>
            <Text mt="15px">
              Write tests like unit tests and e2e ( API ) to make improve the
              codebase quality.
            </Text>
            <Text mt="15px">
              Proactively identify and troubleshoot issues, implement fixes, and
              ensure portal stability and performance.
            </Text>
            <Text mt="15px">
              Familiar myself with various technologies like AWS Services,
              Serverless ( Event Driven Achritecture ), React, Node etc... to
              craft scaling solution that solve real world problems
            </Text>
            <SkillTags
              skills={[
                "Amplify",
                "DynamoDB",
                "React",
                "JavaScript",
                "AWS",
                "Serverless",
                "Gitlab",
                "NodeJS",
                "Jest",
              ]}
            />
          </AnimatedText>
        </Flex>
      </Flex>
    </Flex>
  );
};
