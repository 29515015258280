import React from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import { AnimatedText } from "../components";
import { motion } from "framer-motion";

const MotionText = motion(Text);

export const AboutSection = () => {
  return (
    <Box
      width="100%"
      height="100%"
      color="#fff"
      fontSize="18px"
      lineHeight={"33px"}
    >
      <MotionText>
        <AnimatedText
          appliedWordVariants={{
            hidden: { opacity: 0, x: -10 },
            visible: (i) => ({
              opacity: 1,
              x: 0,
              transition: {
                delay: i * 0.025,
                duration: 0.3,
              },
            }),
          }}
        >
          On a random day in 2020, when I was 21 years old I decided to pursue a
          career in tech as a self-taught dev, why didn't I go to university or
          college at this time of age you may ask ? Simply because at that
          point, my family couldn't afford any tuition fees and I was working at
          a McDonald's to support myself, with all the free time and a little
          bit money I have saved up, I decided that I had to pick up a lifelong
          skill that will allow me to grow into something bigger, and so the
          journey starts.
        </AnimatedText>
      </MotionText>
      <MotionText mt="25px">
        <AnimatedText>
          Fast-forward to 2021 March, I landed my first ever developer job as a
          <Text as="span" color="#C0C0C0">
            Junior Developer
          </Text>
          for a start-up called
          <Link
            href="https://www.sendpayments.com/"
            target="_blank"
            rel="noreferrer"
            color="#ed3d86"
            fontWeight={"bold"}
            textDecoration={"underline"}
          >
            SendFX Limited
          </Link>
          in Australia. Since then, I have developed my skills and built
          professional software like websites and APIs to grow the company from
          <Text as="span" color="#C0C0C0">
            10M
          </Text>
          evaluation when I first joined to
          <Text color="#FFD700" as="span">
            100M
          </Text>
          evaluation.
        </AnimatedText>
      </MotionText>
      <MotionText mt="25px">
        <AnimatedText>
          These days I work as a
          <Text color="#FFD700" as="span">
            Mid-level FullStack Engineer
          </Text>
          with
          <Text color="#FFD700" as="span">
            4 years+ of experience
          </Text>
          in a team of 10+ engineers, QAs, BAs, and Designers to help people
          transfer hundreds of millions of 💵 around the world every year.
        </AnimatedText>
      </MotionText>
      <MotionText mt="25px">
        <AnimatedText>
          In my free time, I like to study new technologies, hang around my
          loved ones, and just enjoy life.
        </AnimatedText>
      </MotionText>
      <MotionText mt="25px">
        <AnimatedText>Thank you for reading.</AnimatedText>
      </MotionText>
    </Box>
  );
};
