import { useState } from "react";
import { Box, Flex, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import { Navbar, LinkList, CursorEffect, ScrollIndicator } from "./components";
import {
  AboutSection,
  ExperienceSection,
  SkillSection,
  ProjectsSection,
} from "./pages";

const Portfolio = () => {
  const [isMobileView] = useMediaQuery("(max-width: 1100px)");

  const sections = {
    about: "About",
    exp: "Experience",
    skills: "Skills",
    project: "Projects",
  };

  const [activeSection, setActiveSection] = useState(sections.about);
  const isAbout = activeSection === sections.about;
  const isExp = activeSection === sections.exp;
  const isSkill = activeSection === sections.skills;
  const isProject = activeSection === sections.project;

  return (
    <>
      <CursorEffect />
      <Box
        bgColor="#141414"
        maxW="100vw"
        minH="100vh"
        pt="5%"
        pl="10%"
        pr="10%"
        justifyContent="center"
        alignItems="center"
        fontFamily={"Roboto Slab"}
        flexDir={isMobileView ? "column" : "row"}
        pb={isMobileView ? "100px" : "0px"}
      >
        {isMobileView && (
          <Navbar
            sections={sections}
            clickHandler={setActiveSection}
            activeSection={activeSection}
          />
        )}
        <Flex
          width={isMobileView ? "100%" : "40%"}
          height={isMobileView ? "100vh" : "100%"}
          flexDir="column"
          justifyContent={isMobileView ? "center" : "center"}
          alignItems={"start"}
          overflow={"hidden"}
          position={isMobileView ? "initial" : "fixed"}
        >
          <Heading size="4xl" color="white" mb="10px">
            Tri Tran
          </Heading>
          <Heading size="lg" color="white" mb="10px">
            Full-Stack Engineer
          </Heading>
          <Text color="#838485" fontSize="18px">
            I help businesses convey their ideas to life.
          </Text>
          {isMobileView === false && (
            <Navbar
              sections={sections}
              clickHandler={setActiveSection}
              activeSection={activeSection}
            />
          )}
          <LinkList />
          {isMobileView && <ScrollIndicator />}
        </Flex>
        <Flex
          width={isMobileView ? "100%" : "50%"}
          ml={isMobileView ? "0%" : "50%"}
        >
          {isAbout && <AboutSection />}
          {isExp && <ExperienceSection />}
          {isSkill && <SkillSection />}
          {isProject && <ProjectsSection />}
        </Flex>
      </Box>
    </>
  );
};

export default Portfolio;
