import React from "react";
import { Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

const wordVariants = {
  hidden: { opacity: 0, x: -10 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.05,
      duration: 0.3,
    },
  }),
};

export const AnimatedText = ({
  children,
  appliedWordVariants = wordVariants,
}) => {
  const words = React.Children.toArray(children).flatMap((child) =>
    typeof child === "string" ? child.split(" ") : child
  );

  return (
    <Text as="span" display="inline-block">
      {words.map((word, index) => (
        <motion.span
          key={index}
          custom={index}
          variants={appliedWordVariants}
          initial="hidden"
          animate="visible"
          style={{ display: "inline-block", marginRight: "4px" }}
        >
          {word}
        </motion.span>
      ))}
    </Text>
  );
};
