import React, { useEffect, useRef, useState } from "react";
import "./CursorEffect.css"; // Import the CSS file

export const CursorEffect = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [outerPosition, setOuterPosition] = useState({ x: 0, y: 0 });
  const animationRef = useRef();

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const updateOuterCursor = () => {
      setOuterPosition((prev) => {
        const xDiff = cursorPosition.x - prev.x;
        const yDiff = cursorPosition.y - prev.y;
        const newX = prev.x + xDiff * 0.07; // Adjust the factor for trailing effect
        const newY = prev.y + yDiff * 0.12; // Adjust the factor for trailing effect
        return { x: newX, y: newY };
      });
      animationRef.current = requestAnimationFrame(updateOuterCursor);
    };

    updateOuterCursor(); // Start the animation
    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [cursorPosition]);

  return (
    <div className="cursor-container">
      <div
        className="cursor-outer"
        style={{ left: outerPosition.x, top: outerPosition.y }}
      />
      <div
        className="cursor-inner"
        style={{ left: cursorPosition.x, top: cursorPosition.y }}
      />
    </div>
  );
};
