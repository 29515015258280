import { Flex, useMediaQuery } from "@chakra-ui/react";
import { SelectOptions } from "./SectionOptions";

export const Navbar = ({ sections, activeSection, clickHandler }) => {
  const [isMobileView] = useMediaQuery("(max-width: 500px)");
  const [isIpadView] = useMediaQuery("(max-width: 1100px)");
  const sectionEntries = Object.entries(sections);

  if (isIpadView && !isMobileView) {
    return (
      <Flex
        width="100%"
        flexDir="row"
        flexWrap={isMobileView ? "wrap" : "initial"}
        justifyContent="center"
        gap={"25px"}
      >
        {sectionEntries.map(([key, section]) => (
          <SelectOptions
            key={key}
            onClickHandler={() => {
              clickHandler(section);
              window.scrollBy({
                top: window.innerHeight * 1.1,
                behavior: "smooth",
              }); // Scroll down 100vh
            }}
            section={section}
            isActive={activeSection === section}
          />
        ))}
      </Flex>
    );
  }

  if (isMobileView) {
    return (
      <Flex
        flexDir="row"
        flexWrap={isMobileView ? "wrap" : "initial"}
        gap={isMobileView ? "10px" : "15px"}
        mt={isMobileView ? "5px" : "25px"}
      >
        <Flex width="100%" justifyContent="space-between">
          {sectionEntries.slice(0, 2).map(([key, section]) => (
            <SelectOptions
              key={key}
              onClickHandler={() => {
                clickHandler(section);
                window.scrollBy({
                  top: window.innerHeight * 1.1,
                  behavior: "smooth",
                }); // Scroll down 100vh
              }}
              section={section}
              isActive={activeSection === section}
            />
          ))}
        </Flex>

        <Flex width="100%" justifyContent="space-between" mt="5px">
          {sectionEntries.slice(2, 4).map(([key, section]) => (
            <SelectOptions
              key={key}
              onClickHandler={() => {
                clickHandler(section);
                window.scrollBy({
                  top: window.innerHeight * 1.1,
                  behavior: "smooth",
                }); // Scroll down 100vh
              }}
              section={section}
              isActive={activeSection === section}
            />
          ))}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" mt="50px">
      {Object.values(sections).map((section) => {
        const isActive = activeSection === section;

        return (
          <SelectOptions
            onClickHandler={() => clickHandler(section)}
            key={section}
            section={section}
            isActive={isActive}
          />
        );
      })}
    </Flex>
  );
};
