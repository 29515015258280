import { Flex, Box, Heading, Text, useMediaQuery } from "@chakra-ui/react";

export const ProjectContainer = ({
  link,
  projectImage,
  projectName,
  secondLine,
}) => {
  const [isMobileView] = useMediaQuery("(max-width: 1100px)");

  return (
    <Flex
      flexDir="column"
      justifyContent={"center"}
      alignItems="center"
      mb={isMobileView ? "0px" : "50px"}
      width="100%"
    >
      <Heading
        mb="20px"
        size="3xl"
        fontFamily={"Macondo Swash Caps"}
        textAlign={"center"}
      >
        {projectName}
      </Heading>
      <Text
        fontFamily={"Macondo Swash Caps"}
        mt="10px"
        mb="50px"
        fontSize="25px"
      >
        {secondLine}
      </Text>
      <Flex
        className="hover-container"
        flexDir="column"
        alignItems="center"
        position="relative"
        mb="50px"
        width="100%"
      >
        <Box
          position="relative"
          w="100%"
          display="inline-block"
          border="2px solid white"
          borderRadius="20px"
          _hover={{
            "&::after": {
              opacity: 1,
              transform: "scale(1.1)",
            },
            "~ .click-text": {
              opacity: 1,
              transform: "translateY(0)",
            },
          }}
          _after={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: "inherit",
            border: "2px solid rgba(255, 255, 255, 0.5)",
            transform: "scale(1)",
            transition: "transform 0.5s ease, opacity 0.5s ease",
            opacity: 0,
            zIndex: -1,
          }}
          onClick={() => window.open(link, "_blank")}
        >
          {projectImage}
        </Box>
        <Text
          className="click-text"
          mt="30px"
          fontSize="25px"
          color="white"
          fontFamily="Macondo Swash Caps"
          opacity={0}
          transform="translateY(10px)"
          transition="opacity 0.5s ease, transform 0.5s ease"
        >
          Click Me
        </Text>
      </Flex>
    </Flex>
  );
};
