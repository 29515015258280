import { Flex, Text, Heading, useMediaQuery } from "@chakra-ui/react";
import { AnimatedText } from "../components";

export const SkillHeading = ({ header }) => {
  return (
    <Heading mb="10px" textDecoration="underline" fontStyle="italic">
      {header}
    </Heading>
  );
};

export const SkillSection = () => {
  const [isMobileView] = useMediaQuery("(max-width: 1100px)");

  return (
    <AnimatedText>
      <Flex
        width="100%"
        height="100%"
        color="#fff"
        fontSize="18px"
        lineHeight="33px"
        flexDir="column"
      >
        <Flex flexGrow={1} flexDir={isMobileView ? "column" : "row"}>
          <Flex flexDir="column" w={isMobileView ? "100%" : "50%"}>
            <SkillHeading header="FrontEnd" />
            <Text>React (Hooks, Routers)</Text>
            <Text>JavaScript (ES6+) / TypeScript</Text>
            <Text>Chakra-UI / MaterialUI</Text>
            <Text>Responsive Layout</Text>
            <Text>State Management Zustand</Text>
            <Text>ThreeJS (hobby)</Text>
          </Flex>
          <Flex
            flexDir="column"
            w={isMobileView ? "100%" : "50%"}
            mt={isMobileView ? "30px" : "0"}
          >
            <SkillHeading header="Backend" />
            <Text>Restful APIs</Text>
            <Text>NodeJS Express</Text>
            <Text>Serverless Framework SST</Text>
            <Text>MongoDB NoSQL</Text>
            <Text>PostgreSQL</Text>
            <Text>
              AWS Cloud Services IAM, Cognito, API Gateway, Lambda Function,
              CloudWatch
            </Text>
          </Flex>
        </Flex>

        <Flex flexGrow={1} flexDir={isMobileView ? "column" : "row"} mt="30px">
          <Flex flexDir="column" w={isMobileView ? "100%" : "50%"}>
            <SkillHeading header="Tools" />
            <Text>CI/CD Circle-CI and Gitlab</Text>
            <Text>Version Control Git</Text>
            <Text>Build tools ESlint, Prettier</Text>
          </Flex>
          <Flex
            flexDir="column"
            w={isMobileView ? "100%" : "50%"}
            mt={isMobileView ? "30px" : "0"}
          >
            <SkillHeading header="Testings" />
            <Text>Unit tests Jest</Text>
            <Text>Automation Tests Playwright</Text>
            <Text>E2E API Tests SuperTest</Text>
          </Flex>
        </Flex>

        <Flex flexGrow={1} flexDir="column" mt="30px">
          <Flex flexDir="column" w="100%">
            <SkillHeading header="Software Development Experience" />
            <Text>
              Fluent English communication skills (4+ years working &
              communicating directly with native Australian speakers)
            </Text>
            <Text>Proficient with JIRA and Confluence</Text>
            <Text>Agile & Scrum</Text>
            <Text>Familiar with Figma</Text>
          </Flex>
        </Flex>
      </Flex>
    </AnimatedText>
  );
};
