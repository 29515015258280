import { Flex, Link, useMediaQuery } from "@chakra-ui/react";
import LinkButton from "./LinkButton";
import { BiLogoDevTo } from "react-icons/bi";

export const LinkList = () => {
  const [isMobileView] = useMediaQuery("(max-width: 1100px)");

  return (
    <Flex
      flexDir="row"
      mt={isMobileView ? "20px" : "200px"}
      mb={isMobileView ? "50px" : "0px"}
    >
      <LinkButton
        link="https://www.linkedin.com/in/tr%C3%AD-tr%E1%BA%A7n-6861331a4/"
        iconName="fab fa-linkedin"
      />
      <LinkButton link="https://github.com/Tris-909" iconName="fab fa-github" />
      <Link
        href="https://dev.to/tris909"
        target="_blank"
        rel="noopener noreferrer"
        mr="25%"
      >
        <BiLogoDevTo fontSize="2.5rem" color="white" />
      </Link>
    </Flex>
  );
};
