import { Flex, Image, useMediaQuery } from "@chakra-ui/react";
import { AnimatedText, ProjectContainer } from "../components";
import Smaug from "../assets/smaug-close.png";
import GalaxyGenerator from "../assets/galaxyGenerator.png";

export const ProjectsSection = () => {
  const [isMobileView] = useMediaQuery("(max-width: 1100px)");

  return (
    <Flex
      width="100%"
      height="100%"
      color="#fff"
      fontSize="18px"
      lineHeight={"33px"}
      flexDir="column"
    >
      <AnimatedText>
        <Flex
          flexDir="column"
          justifyContent={"center"}
          alignItems="center"
          mt={isMobileView ? "0px" : "50px"}
          mb="50px"
        >
          <ProjectContainer
            link="https://glacier-verse-582.notion.site/Smaug-12abd7d825b280c29ad2cce1e208cda1?pvs=74"
            projectImage={
              <Image
                src={Smaug}
                width="100%"
                minHeight={isMobileView ? "250px" : "400px"}
                borderRadius="inherit"
                zIndex={0}
              />
            }
            projectName="Smaug"
            secondLine="Assets Management Web"
          />
          <ProjectContainer
            link="https://three-js-galaxy-generator-seven.vercel.app/"
            projectImage={
              <Image
                src={GalaxyGenerator}
                width="100%"
                minHeight={isMobileView ? "250px" : "400px"}
                borderRadius="inherit"
                zIndex={0}
              />
            }
            projectName="Galaxy Generator"
            secondLine="Web 3D ThreeJS Creation"
          />
        </Flex>
      </AnimatedText>
    </Flex>
  );
};
