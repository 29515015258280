import { Flex, Box } from "@chakra-ui/react";

export const SkillTags = ({ skills }) => {
  return (
    <Flex flexDir={"row"} mt="25px" flexWrap={"wrap"}>
      {skills.map((skill) => {
        return (
          <Box
            key={skill}
            p="5px"
            border="1px solid white"
            m="5px"
            borderRadius="10px"
            whiteSpace={"initial"}
          >
            {skill}
          </Box>
        );
      })}
    </Flex>
  );
};
