import React, { useEffect, useState } from "react";
import "../ScrollIndicator/index.css";

export const ScrollIndicator = () => {
  const [atBottom, setAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const nearBottom = document.documentElement.scrollHeight - 50;

      setAtBottom(scrollPosition >= nearBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      id="sd-container"
      onClick={scrollToTop}
      className={atBottom ? "at-bottom" : ""}
    >
      <div className={`arrow ${atBottom ? "reverse" : ""}`}></div>
      <div className={`arrow ${atBottom ? "reverse" : ""}`}></div>
    </div>
  );
};
