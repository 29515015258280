import { Flex, Box, Text, useMediaQuery } from "@chakra-ui/react";

export const SelectOptions = ({ onClickHandler, isActive, section }) => {
  const [isMobileView] = useMediaQuery("(max-width: 1100px)");

  return (
    <Flex
      flexDir="row"
      key={section}
      align="center"
      onClick={() => onClickHandler()}
      mt={isMobileView ? "0px" : "10px"}
    >
      {!isMobileView && (
        <Box
          width={isActive ? "80px" : "30px"}
          h="1px"
          bgColor={isActive ? "white" : "#838485"}
          mr="10px"
          transition="all 0.2s ease"
        ></Box>
      )}

      <Text
        color={isActive ? "white" : "#838485"}
        fontWeight="600"
        fontSize="25px"
        cursor="pointer"
      >
        {section}
      </Text>
    </Flex>
  );
};
